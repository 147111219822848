import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer id="homeFooter" className="" style={{backgroundColor: "#3498D8"}}>
                <div className="container py-4">
                    <div className="row">
                        <div className="d-flex justify-content-center social-icons mt-4">
                            {/* <Link className="icon-style-1"><i className="fa-brands fa-facebook-f"></i></Link>
                            <Link className="icon-style-1"><i className="fa-brands fa-instagram"></i></Link> */}
                            <Link className="icon-style-1" to={"https://github.com/quangle2706/"} target="_blank"><i className="fa-brands fa-github"></i></Link>
                            <Link className="icon-style-1" to={"https://www.linkedin.com/in/quang-tuan-le-498474214/"} target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
                        </div>
                        <div className="text-center my-4">
                            <h5 className="text-white">Copyright © 2023 • Quang Tuan Le</h5>
                            <h6 className="text-white">The One and Only</h6>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer