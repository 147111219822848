import { Link } from "react-router-dom";

const AboutContact = () => {
    return (
        <>
            <section id="sectionContact" className="section-contact">
                <div className="container">
                    <div className="section-heading">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Contact</h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Let's keep in touch</h3>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.</p> */}
                            </div>
                        </div>
                        <div className="block-my-contact-form">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="block-my-contacts">
                                        <figure className="my-contact">
                                            <img width={"50px"} src="/assets/about-page/email.png" alt=""/>
                                            <figcaption>
                                                <span className="my-contact-heading">Email</span>
                                                <span className="my-contact-data">quangle2706@gmail.com</span>
                                            </figcaption>
                                        </figure>
                                        <figure className="my-contact">
                                            <img width={"50px"}  src="/assets/about-page/webpage.png" alt=""/>
                                            <figcaption>
                                                <span className="my-contact-heading">Website</span>
                                                <span className="my-contact-data">thequangle.com/contact</span>
                                            </figcaption>
                                        </figure>
                                        {/* <figure className="my-contact">
                                            <img width={"50px"}  src="/assets/home-page/hero-bg-2.png" alt=""/>
                                            <figcaption>
                                                <span className="my-contact-heading">Email</span>
                                                <span className="my-contact-data">quangle2706@gmail.com</span>
                                            </figcaption>
                                        </figure> */}
                                    </div>
                                    <div className="block-my-social">
                                        <h3>I'm in the social networks</h3>
                                        <ul className="social-links-list">
                                            <li><Link className="my-social-link" to={"https://github.com/quangle2706/"} target="_blank"><img width={"50px"} src="/assets/about-page/github.png" alt=""/></Link></li>
                                            <li><Link className="my-social-link" to={"https://www.linkedin.com/in/quang-tuan-le-498474214/"} target="_blank"><img width={"50px"} src="/assets/about-page/linkedin.png" alt=""/></Link></li>
                                            {/* <li><Link className="my-social-link"><img width={"50px"} src="/assets/home-page/hero-bg-2.png" alt=""/></Link></li>
                                            <li><Link className="my-social-link"><img width={"50px"} src="/assets/home-page/hero-bg-2.png" alt=""/></Link></li>
                                            <li><Link className="my-social-link"><img width={"50px"} src="/assets/home-page/hero-bg-2.png" alt=""/></Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <form id="contactForm" action="" method="" className="contact-form">
                                        <input type="text" name="visitor-name" placeholder="Your Name"/>
                                        <input type="email" name="visitor-email" placeholder="Your Email"/>
                                        <textarea name="visitor-message" placeholder="Your Message"></textarea>
                                        <button id="contact-form-btn" className="progress-button" type="submit" name="submit">Send</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutContact