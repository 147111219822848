import { useInView } from "react-intersection-observer";
import { useFormik } from "formik";
import * as Yup from 'yup';
import useSubmit from "../../hooks/useSubmit";
import { useAlertContext } from "../../context/AlertContext";
import { useEffect } from "react";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea, VStack } from "@chakra-ui/react";

const HomeContact = () => {

    const [leftContentRef, leftContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

      const [rightContentRef, rightContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

    const {isLoading, response, submit} = useSubmit();
    const {onOpen} = useAlertContext();

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
        },
        onSubmit: (values) => {
            submit('', values); // submit to url and pass the data
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            email: Yup.string().email("Invalid email address").required("Required"),
            message: Yup.string().min(25, "Must be at least 25 characters").required("Required"),
        }),
    });

    useEffect(() => {
        if (response) {
            onOpen(response.type, response.message);
            if (response.type === 'success') {
                formik.resetForm();
            }
        }
        // eslint-disable-next-line
    }, [response]);

    return (
        <>
            <section className="contact-section py-5">
                <div className="container">
                    <div className="row">
                        <div className={`contact-form col-lg-6 col-sm-12 left-content ${leftContentInView ? 'fade-in-left' : ''}`} ref={leftContentRef}>
                            <form className="p-4" onSubmit={formik.handleSubmit}>
                                <div className="home-topic mb-1">Get in touch</div>
                                <h1 className="home-title mb-4">CONTACT</h1>
                                <VStack spacing={4}>
                                    <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
                                        <FormLabel htmlFor="name">Name</FormLabel>
                                        <Input id="name" name="name" {...formik.getFieldProps("name")} errorBorderColor='crimson' />
                                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <Input id="email" name="email" {...formik.getFieldProps("email")} errorBorderColor='crimson' />
                                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!formik.errors.message && formik.touched.message}>
                                        <FormLabel htmlFor="message">Name</FormLabel>
                                        <Textarea id="message" name="message" height={250} {...formik.getFieldProps("message")} errorBorderColor='crimson' />
                                        <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                                    </FormControl>
                                    <Button type="submit" colorScheme="blue" width="full" isLoading={isLoading}>Send</Button>
                                </VStack>
                            </form>
                        </div>
                        <div className={`contact-img-box col-lg-6 col-sm-12 right-content ${rightContentInView ? 'fade-in-right' : ''}`} ref={rightContentRef}>
                            <img className="mw-100" src="/assets/home-page/cute-chibi.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeContact