import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const BlogHeader = () => {

    const [isWideScreen, setIsWideScreen] = useState(false);

    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 992);
    };

    useEffect(() => {
      handleResize(); // Set initial screen width on component mount

      // Add event listener to update screen width on window resize
      window.addEventListener('resize', handleResize);

      // Remove event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        <>
            <header id="blogHeader">
                <div className="container">
                    <nav className="blog-navbar navbar navbar-expand-lg navbar-light bg-light">
                        <Link className="navbar-brand" to="/"><img src="/assets/Logo-cute-3.png" alt="logo" width={"50px"} /></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className={isWideScreen ? 'collapse navbar-collapse d-flex justify-content-end' : 'collapse navbar-collapse'} id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/projects">Projects</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/blogs">Blogs</Link>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="/blogs" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Blogs
                                    </Link>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="#">Technology</Link>
                                        <Link className="dropdown-item" to="#">Algorithm</Link>
                                    <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">Lifestyle</Link>
                                    </div>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about">About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">Contact</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"><i className="fa-solid fa-magnifying-glass"></i></Link>
                                </li>
                            </ul>
                            <div className="social-list hide-on-mobile">
                                <Link><i className="fa-brands fa-facebook-f"></i></Link>
                                <Link><i className="fa-brands fa-instagram"></i></Link>
                                <Link><i className="fa-brands fa-github"></i></Link>
                                <Link><i className="fa-brands fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default BlogHeader