const AboutSkills = () => {
    return (
        <>
            <section id="sectionSkills" className="section-skills">
                <div className="container">
                    <div className="section-heading">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Skills</h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="block-my-skills">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>My skills</h3>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.</p> */}
                                    <ul>
                                        <li>2+ years of experience in Web Development; Proficient in HTML, CSS, JavaScript, Gatsby, AngularJS, Flutter, ReactJS, MongoDB, Supabase, Firebase; 1+ year experience in Gatsby for building fast and modern websites.</li>
                                        <li>Proficient in Flutter for mobile app development, with 1+ years of hands-on experience. Strong understanding of Flutter architecture, widgets, state management, and its libraries (Google maps, SQFLite).</li>
                                        <li>2+ years experienced in C/C++, Java, Python for developing school projects, parallel programming, OOP.</li>
                                        <li>Good at Data Structures and Algorithms, my code keeps clean & optimized. Architectures MVP, MVVM.</li>
                                        <li>Proficient in Git version control system, including creating repositories, branching, merging, and resolving conflicts.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="block-circles-skills">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="skill-wrapper">
                                        <div id="circle1" data-progress-percent="84" className="circle-skill">
                                            <canvas width={126} height={126}></canvas>
                                            <strong>84<i>%</i></strong>
                                        </div>
                                        <div className="circle-skill-heading">
                                            <span className="my-skill-heading">Frontend<br></br>Development</span>
                                            <span className="my-skill-exp">2 years</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="skill-wrapper">
                                        <div id="circle2" data-progress-percent="58" className="circle-skill">
                                            <canvas width={126} height={126}></canvas>
                                            <strong>62<i>%</i></strong>
                                        </div>
                                        <div className="circle-skill-heading">
                                            <span className="my-skill-heading">Mobile<br></br>Development</span>
                                            <span className="my-skill-exp">2 years</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="skill-wrapper">
                                        <div id="circle1" data-progress-percent="78" className="circle-skill">
                                            <canvas width={126} height={126}></canvas>
                                            <strong>78<i>%</i></strong>
                                        </div>
                                        <div className="circle-skill-heading">
                                            <span className="my-skill-heading">Database<br></br>Management</span>
                                            <span className="my-skill-exp">1 years</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="block-my-knowledge">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Knowledge</h3>
                                    <p></p>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <ul className="knowledge-list">
                                        <li><i className="fa-solid fa-check"></i>Data Structures</li>
                                        <li><i className="fa-solid fa-check"></i>Algorithm</li>
                                        <li><i className="fa-solid fa-check"></i>Parallel Programming</li>
                                        <li><i className="fa-solid fa-check"></i>Git/Version Control</li>
                                    </ul>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <ul className="knowledge-list">
                                        <li><i className="fa-solid fa-check"></i>HTML5/CSS3/JavaScript</li>
                                        <li><i className="fa-solid fa-check"></i>ReactJS/Gatsby</li>
                                        <li><i className="fa-solid fa-check"></i>UX/UI/Figma</li>
                                        <li><i className="fa-solid fa-check"></i>NodeJS</li>
                                    </ul>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <ul className="knowledge-list">
                                        <li><i className="fa-solid fa-check"></i>C/C++/C#</li>
                                        <li><i className="fa-solid fa-check"></i>Java</li>
                                        <li><i className="fa-solid fa-check"></i>SQL/MySQL</li>
                                        <li><i className="fa-solid fa-check"></i>Python</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="block-my-languages">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Language skills</h3>
                                    <p></p>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="block-skillbars">
                            <div className="row">
                                <div className="col-md-12">
                                    <div id="skillbar1" className="skillbar-block">
                                        <span className="hidden-xs skillbar-exp">99%</span>
                                        <div className="skillbar-wrapper">
                                            <span className="skillbar-heading">Vietnamese</span>
                                            <div data-progress-percent="99" className="skillbar-container">
                                                <div className="bar-skill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div id="skillbar2" className="skillbar-block">
                                        <span className="hidden-xs skillbar-exp">70%</span>
                                        <div className="skillbar-wrapper">
                                            <span className="skillbar-heading">English Intermediate</span>
                                            <div data-progress-percent="99" className="skillbar-container">
                                                <div className="bar-skill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutSkills