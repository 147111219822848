import { Link } from "react-router-dom";

const AboutTheAuthor = () => {

    const latestArticles = [
        {
            backgroundImg: "/assets/articles/dynamic-programming.jpg",
            tag: "Algorithm",
            date: "August 10, 2023 - 5 min read",
            title: "Dynamic Programming: Solving Complex Problems",
            introduction: "Dynamic programming is a powerful algorithmic technique in the field of computer science that enables us to solve complex problems by breaking them down into simpler subproblems and reusing solutions to those subproblems. Unlike its name might suggest, dynamic programming has nothing to do with programming languages but is a methodical approach to problem-solving that often leads to efficient and elegant solutions. In this article, we'll explore the fundamental concepts of dynamic programming, its key principles, and its applications across various domains.",
            content: [
                {
                    subtopic: "Understanding Dynamic Programming",
                    name: "At its core, dynamic programming involves solving problems by combining the solutions to overlapping subproblems. It's a technique particularly suited for optimization problems and those that can be broken down into smaller, simpler instances. The process often follows these general steps:",
                    description: [
                        "Identify Overlapping Subproblems: Before employing dynamic programming, it's essential to recognize that the problem can be divided into overlapping subproblems. These subproblems should be smaller in scale and easier to solve than the original problem.",
                        "Store Subproblem Solutions: Dynamic programming leverages the concept of memoization, where the solutions to subproblems are stored in a data structure (usually an array or a matrix). This prevents redundant computations, as the solutions are reused when needed.",
                        "Build Up Optimal Solution: By solving subproblems bottom-up (from the simplest to the most complex), dynamic programming constructs the optimal solution to the original problem. This often involves combining solutions to subproblems in an iterative manner."
                    ]
                },
                {
                    subtopic: "Key Principles of Dynamic Programming",
                    name: "",
                    description: [
                        "Optimal Substructure: This property asserts that an optimal solution to the original problem contains optimal solutions to its subproblems. In other words, solving smaller subproblems contributes to the overall optimal solution.",
                        "Overlapping Subproblems: Dynamic programming thrives on this principle, where a problem can be broken down into subproblems that share sub-subproblems. By storing and reusing solutions to these overlapping subproblems, dynamic programming avoids unnecessary recalculations."
                    ]
                },
                {
                    subtopic: "Applications of Dynamic Programming",
                    name: "Dynamic programming finds applications across various domains within computer science and beyond:",
                    description: [
                        "Algorithm Optimization: Problems like the Fibonacci sequence or computing binomial coefficients involve repetitive calculations. Dynamic programming can significantly reduce the time complexity of these calculations.",
                        "Sequence Alignment: In bioinformatics, dynamic programming is used for sequence alignment tasks, such as DNA or protein sequence comparisons, which help identify functional similarities between biological molecules.",
                        "Shortest Paths: Algorithms like Dijkstra's and Floyd-Warshall, which find the shortest paths in graphs, utilize dynamic programming to optimize route-finding in transportation networks and computer networks.",
                        "String Matching: Dynamic programming can enhance string matching algorithms, enabling efficient text search and pattern recognition in computational linguistics and data analysis.",
                        "Resource Allocation: Problems involving the allocation of resources, such as the knapsack problem, can be efficiently solved using dynamic programming by considering the optimal allocation of resources step by step."
                    ]
                }
            ],
            conclusion: "Dynamic programming stands as a testament to the elegance and power of algorithmic problem-solving techniques. By decomposing complex problems into manageable subproblems and efficiently reusing solutions, dynamic programming allows us to tackle challenges that would otherwise be intractable. Its versatility and applicability across various domains make it an indispensable tool in the arsenal of every computer scientist, enabling the creation of efficient and optimized algorithms for a wide range of problems."
        }
    ]

    return (
        <>
            <section className="about-the-author">
                <div className="author-title">About the Author</div>
                <div className="container">
                    <div className="row">
                        <div className="author-information my-2 col-lg-7 col-md-12">
                            <div className="about-author">
                                <div className="author-avatar avatar-effect">
                                    <img src="assets/blog-page/cute-avatar.jpg" alt="" />
                                </div>
                                <div className="author-info">
                                    <h4>Quang Tuan Le</h4>
                                    <h6>Founder & Developer</h6>
                                    <div className="social-icons">
                                        {/* <Link to="#"><i className="fa-brands fa-facebook-f"></i></Link>
                                        <Link to="#"><i className="fa-brands fa-instagram"></i></Link> */}
                                        <Link to="https://github.com/quangle2706" target="_blank"><i className="fa-brands fa-github"></i></Link>
                                        <Link to="https://www.linkedin.com/in/quang-tuan-le-498474214/" target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <p className="text-center">Hello! My name is Quang Tuan Le. I am a software engineer, and I love to create applications.</p>
                            <div className="text-center block">
                                <div className="inline-flex"><Link to="/blogs" className="button-style-1">View All Articles</Link></div>
                            </div>
                        </div>
                        <div className="author-latest-articles my-2 col-lg-5 col-md-12">
                            <p className="text-center">Check latest articles from this author:</p>
                            {
                                latestArticles.map((article, index) => (
                                    <div className="recent-post" key={index}>
                                        <div className="recent-post-img">
                                            <Link to="/blogs"><img src={article.backgroundImg} alt="" /></Link>
                                        </div>
                                        <div className="recent-post-info">
                                            <div className="title small"><Link to="/blog-detail">{article.title}</Link></div>
                                            <div className="item mt-1">{article.date}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutTheAuthor