import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const HomeHero = () => {

    const [leftContentRef, leftContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

      const [rightContentRef, rightContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

    return (
        <>
            <section className="hero-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className={`hero-title col-lg-6 col-md-12 align-middle left-content ${leftContentInView ? 'fade-in-left' : ''}`} ref={leftContentRef}>
                            <h1 className="my-2 home-title display-2">Hi, I'm Quang Le</h1>
                            <h4 className="home-subtitle">
                                I am a passionate software engineer which I develop website, mobile, and desktop applications.<br></br>I'm thrilled to have you visit my website.
                            </h4>
                            <div className="my-4"><Link className="home-btn button-87" to={"https://www.linkedin.com/in/quang-tuan-le-498474214/"} target="_blank">Click Me</Link></div>
                        </div>
                        <div className={`hero-img col-lg-6 col-md-12 right-content ${rightContentInView ? 'fade-in-right' : ''}`} ref={rightContentRef}>
                            <img src="/assets/home-page/hero-bg-2.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeHero