import BlogFooter from "../../components/BlogFooter";
import BlogHeader from "../../components/BlogHeader";
import AboutMeAside from "./components/AboutMeAside";
import BlogBox from "./components/BlogBox";
import CategoryBox from "./components/CategoryBox";
import FollowMeAside from "./components/FollowMeAside";
import RecentPostsAside from "./components/RecentPostsAside";
import TagAside from "./components/TagAside";

export default function BlogsPage() {
    return (
        <div className="blog-page-body">
            <BlogHeader />
            <div className="container">
                <CategoryBox />
                <div className="row my-5">
                    <main className="main-blog col-lg-8 col-md-12">
                        <div className="blog-list">
                            <BlogBox />
                            {/* <BlogBox />
                            <BlogBox /> */}
                        </div>
                    </main>
                    <aside className="col-lg-4 col-md-12">
                        <AboutMeAside />
                        <RecentPostsAside className="hide-on-mobile" />
                        <FollowMeAside className="hide-on-mobile" />
                        <TagAside className="hide-on-mobile" />
                    </aside>
                </div>
            </div>
            <BlogFooter />
        </div>
    );
}