import { useState } from "react";
import { Link } from "react-router-dom";

const AboutHeader = () => {

    const [isToggled, setIsToggled] = useState(false);

    return (
        <>
            <div className="responsive-menu">
                <span className="responsive-menu-heading">Quang Le</span>
                <Link id="menu-toggle" className="responsive-menu-toggle" to={"#"} onClick={() => {setIsToggled(!isToggled)}}>
                    <img src="http://netgon.net/themeforest/zet/img/icons/whiteorange/menu-button.svg" alt="menu" />
                </Link>
            </div>
            <header id="pageSidebarWrapper" className={`header-sidebar ${isToggled ? 'toggled' : ''}`}>
                <nav id="topNavigation" className="top-navigation">
                    <Link to={"/"} className="logo-wrapper">
                        <img src="/assets/Logo-cute-3.png" alt="Quang Le" />
                    </Link>
                    <ul className="nav top-menu">
                        <li className="has-child-menu active">
                            <Link to={"#sectionIntro"}>Home</Link>
                            <span className="menu-next-btn hidden-md" style={{display: "inline-block"}}>
                                <i class="fa-solid fa-chevron-right"></i>
                            </span>
                            <span className="menu-prev-btn hidden-md" style={{display: "none"}}>
                                <i class="fa-solid fa-chevron-left"></i>
                            </span>
                            <ul className="child-menu">
                                <li><Link to={"/"}>Home</Link></li>
                                <li className="current"><Link to={"/projects"}>Projects</Link></li>
                                <li className="disabled"><Link to={"/blogs"}>Blogs<sup>Soon</sup></Link></li>
                            </ul>
                        </li>
                        <li><Link to={"#sectionAbout"}>About me</Link></li>
                        <li><Link to={"#sectionSkills"}>Skills</Link></li>
                        <li><Link to={"#sectionExperience"}>Experience</Link></li>
                        <li><Link to={"#sectionEducation"}>Education</Link></li>
                        <li><Link to={"#sectionPortfolio"}>Portfolio</Link></li>
                        <li><Link to={"#sectionContact"}>Contact</Link></li>
                    </ul>
                </nav>
            </header>
        </>
    );
}

export default AboutHeader