import { Link } from "react-router-dom";

const FollowMeAside = (props) => {

    const socialList = [
        // {
        //     icon: "fa-brands fa-facebook-f",
        //     name: "Facebook",
        //     url: "",
        // },
        // {
        //     icon: "fa-brands fa-instagram",
        //     name: "Instagram",
        //     url: "",
        // },
        {
            icon: "fa-brands fa-github",
            name: "Github",
            url: "https://github.com/quangle2706/",
        },
        {
            icon: "fa-brands fa-linkedin-in",
            name: "LinkedIn",
            url: "https://www.linkedin.com/in/quang-tuan-le-498474214/",
        }
    ]

    return (
        <>
            <div className={`follow-me-aside ${props.className}`}>
                <div className="follow-me-title title medium">Follow me!</div>
                {
                    socialList.map((item, index) => (
                        <Link className="social-info" to={item.url} key={index} target="_blank">
                            <div className="social-icon bg-white"><i className={item.icon}></i></div>
                            <div className="name">{item.name}</div>
                        </Link>
                    ))
                }
            </div>
        </>
    );
}

export default FollowMeAside