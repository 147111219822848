import { ChakraProvider } from "@chakra-ui/react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import HomeContact from "../Home/components/HomeContact";
import { AlertProvider } from "../context/AlertContext";
import Alert from "../../components/Alert";

export default function ContactPage() {
    return (
        <>
            <ChakraProvider>
                <AlertProvider>
                    <Header />
                    <main>
                        <HomeContact />
                    </main>
                    <Footer />
                    <Alert />
                </AlertProvider>
            </ChakraProvider>
        </>
    );
}