import { Link } from "react-router-dom";

const CategoryBox = () => {
    return (
        <>
            <div className="category-box my-4 mx-5 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="category-box-info col-lg-4 col-md-12">
                            <div className="title small my-2">Category:</div>
                            <Link className="tag-link" to="/blogs">Algorithm</Link>
                            <div className="item my-2">3 - Articles</div>
                        </div>
                        <div className="category-box-content col-lg-8 col-md-12 m-auto">
                            <p className="m-0"> In Computer Science, an algorithm is a list set of instructions, used to solve problems or perform tasks, based on the understanding of available alternatives.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryBox