import React, { useState } from "react";
import Isotope from "isotope-layout";
import { Link } from "react-router-dom";

const ProjectsProject = (props) => {

    // state for storing the isotope object, with an initial value of null
    const [isotope, setIsotope] = React.useState(null);
    // state for storing the filter keyword, with an initial value of *, which matches everything
    const [filterKey, setFilterKey] = React.useState('*');

    React.useEffect(() => {
        setIsotope(
          new Isotope('.filter-container', {
            // filter-container: className of the parent of the isotope elements
            itemSelector: '.grid-item', // filter-item: className of the isotope elements
            layoutMode: 'fitRows', // for horizontal isotope
            percentPosition: true,
            fitRows: {
                // use outer width of grid-sizer for columnWidth
                columnWidth: '.grid-sizer',
              }
          })
        );
    }, []); // [] makes this useEffect work like a componentDidMount in a class component

    React.useEffect(() => {
        if (isotope) {
          // sanity check
          filterKey === '*'
            ? isotope.arrange({ filter: `*` })
            : isotope.arrange({ filter: `.${filterKey}` });
        }
    }, [isotope, filterKey]);

    var projectList = [
        {
            title: "Portfolio Website",
            description: "I developed a portfolio website showcases ...",
            skill: "ReactJS, HTML5/CSS3",
            image: "/assets/home-page/cute-laptop-1.jpg",
            type: "web",
            url: "https://thequangle.com",
        },
        {
            title: "DSA Learning",
            description: "",
            skill: "Mobile Development, Flutter",
            image: "/assets/home-page/cute-laptop-4.jpg",
            type: "mobile",
            url: "https://github.com/quangle2706/my_dsa_app",
        },
        {
            title: "Data Mining",
            description: "",
            skill: "Python, Colab, Numpy/Pandas, Keras",
            image: "/assets/home-page/cute-laptop-3.jpg",
            type: "other",
            url: "https://github.com/quangle2706/ml-class-quangle/tree/main",
        },
        {
            title: "XYZ University - Student DB",
            description: "I developed a portfolio website showcases ...",
            skill: "Java, JavaFX, MySQL",
            image: "/assets/home-page/cute-laptop-2.jpg",
            type: "other",
            url: "https://github.com/quangle2706/Database-XYZ-University",
        }
        // {
        //     name: "Portfolio",
        //     type: "web", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-web-1.jpg",
        //     skills: "HTML5/CSS3",
        //     url: ""
        // },
        // {
        //     name: "Tank Game",
        //     type: "game", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-game-1.jpg",
        //     skills: "C/C++, Data Structures & Algorithm",
        //     url: ""
        // },
        // {
        //     name: "Data Mining",
        //     type: "other", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-rabbit.jpg",
        //     skills: "HTML5/CSS3",
        //     url: ""
        // },
        // {
        //     name: "Tic Tac Toe",
        //     type: "game", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-game-2.jpg",
        //     skills: "C#, Unity",
        //     url: ""
        // },
        // {
        //     name: "Learning DSA",
        //     type: "mobile", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-mobile-1.jpg",
        //     skills: "Dart/Flutter, Supabase",
        //     url: ""
        // },
        // {
        //     name: "Data Mining",
        //     type: "other", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-rabbit.jpg",
        //     skills: "HTML5/CSS3",
        //     url: ""
        // },
        // {
        //     name: "Portfolio",
        //     type: "web", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-web-1.jpg",
        //     skills: "HTML5/CSS3",
        //     url: ""
        // },
        // {
        //     name: "Tank Game",
        //     type: "game", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-game-1.jpg",
        //     skills: "C/C++, Data Structures & Algorithm",
        //     url: ""
        // },
        // {
        //     name: "Tic Tac Toe",
        //     type: "game", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-game-2.jpg",
        //     skills: "C#, Unity",
        //     url: ""
        // },
        // {
        //     name: "Learning DSA",
        //     type: "mobile", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-mobile-1.jpg",
        //     skills: "Dart/Flutter, Supabase",
        //     url: ""
        // },
    ];

    const projectTabList = [
        {
            name: 'All',
            onClick: () => { 
                setFilterKey('*');
                setActiveItem(0);
            }
        },
        {
            name: 'Website',
            onClick: () => { 
                setFilterKey('web');
                setActiveItem(1);
            }
        },
        {
            name: 'Mobile',
            onClick: () => {
                setFilterKey('mobile');
                setActiveItem(2);
            }
        },
        {
            name: 'Game',
            onClick: () => {
                setFilterKey('game');
                setActiveItem(3);
            }
        },
        {
            name: 'Others',
            onClick: () => {
                setFilterKey('other');
                setActiveItem(4);
            }
        }
    ]

    const [activeItem, setActiveItem] = useState(0);

    return (
        <>
            <section className="container">
                <div className="project-section-heading">
                    <div className="project-section-heading-left">
                        <h2 className="project-section-title">My Projects</h2>
                    </div>
                    <div className="project-section-heading-right">
                        <Link className="heading-btn card-btn-1" to="https://github.com/quangle2706" target="_blank"><span>Explore More</span></Link>
                    </div>
                </div>
                <div className="project-tab-bar">
                    <ul>
                        {
                            projectTabList.map((item, index) => (
                                <li className={index === activeItem ? 'active' : ''} key={index}><Link onClick={item.onClick}><span>{item.name}</span></Link></li>
                            ))
                        }
                    </ul>
                </div>
                <div className="height_45"></div>
                <div className="filter-container grid isotope-gutter-30">
                    <div className="grid-sizer"></div>
                    {projectList.map((project, index) => (
                        <div className={`grid-item ${project.type}`} key={index}>
                            <div className="project-item">
                                <Link to={project.url} className="project-card-thumb project-zoom-effect"><img className="project-zoom-item" src={project.image} alt="" /></Link>
                                <div className="project-card-info">
                                    <h3 className="project-card-title">{project.title}</h3>
                                    <h6 className="project-card-skill">{project.skill}</h6>
                                    <hr></hr>
                                    <div className="project-card-footer"><Link className="card-btn-1" to={project.url}><span>See demo <i className="fa-solid fa-chevron-right"></i></span></Link></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}

export default ProjectsProject