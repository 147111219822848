const AboutEducation = () => {
    return (
        <>
            <section id="sectionEducation" className="section-education">
                <div className="container">
                    <div className="section-heading">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Education</h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="block-my-education">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>My education</h3>
                                    <p></p>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="block-timeline">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="timeline">
                                        <li className="event">
                                            <input id="checkbox-edu1" type="radio" name="edu-tl-group" checked />
                                            <label></label>
                                            <div className="thumb company-edu1">
                                                <span>2022 - 2023</span>
                                            </div>
                                            <div className="content-perspective">
                                                <div className="content">
                                                    <div className="content-inner">
                                                        <h3>Florida International University, Miami, Florida</h3>
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="event">
                                            <input id="checkbox-edu2" type="radio" name="edu-tl-group" />
                                            <label></label>
                                            <div className="thumb company-edu2">
                                                <span>2013 - 2017</span>
                                            </div>
                                            <div className="content-perspective">
                                                <div className="content">
                                                    <div className="content-inner">
                                                        <h3>University of Science, HCMUS, Vietnam</h3>
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="event">
                                            <input id="checkbox-edu3" type="radio" name="edu-tl-group" />
                                            <label></label>
                                            <div className="thumb company-edu3">
                                                <span>2023</span>
                                            </div>
                                            <div className="content-perspective">
                                                <div className="content">
                                                    <div className="content-inner">
                                                        <h3>Meta Front-End Developer</h3>
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutEducation