import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Header = () => {

    const [isWideScreen, setIsWideScreen] = useState(false);

    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 992);
    };

    useEffect(() => {
      handleResize(); // Set initial screen width on component mount

      // Add event listener to update screen width on window resize
      window.addEventListener('resize', handleResize);

      // Remove event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        <>
            <header id="homeHeader" className="mx-4">
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <Link className="navbar-brand" to="/"><img src="/assets/Logo-cute-2.png" width="50px" alt="logo" /></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={`collapse navbar-collapse ${isWideScreen ? 'd-flex justify-content-end' : ''}`} id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/projects">Projects</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/blogs">Blogs</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/about">About</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header