import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const ProjectsLanding = () => {

    var settings = {
        focusOnSelect: true,
        arrows: true,
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "0px",
        speed: 500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                }
            },
            // {
            //     breakpoint: 480,
            //     settings: {
            //     slidesToShow: 1,
            //     slidesToScroll: 1
            //     }
            // }
        ]
    };

    var projectList = [
        {
            title: "Portfolio Website",
            description: "I developed a portfolio website showcases ...",
            skill: "ReactJS, HTML5/CSS3",
            image: "/assets/home-page/cute-laptop-1.jpg",
            type: "web",
            url: "https://thequangle.com",
        },
        {
            title: "DSA Learning",
            description: "",
            skill: "Mobile Development, Flutter",
            image: "/assets/home-page/cute-laptop-4.jpg",
            type: "mobile",
            url: "https://github.com/quangle2706/my_dsa_app",
        },
        {
            title: "Data Mining",
            description: "",
            skill: "Python, Colab, Numpy/Pandas, Keras",
            image: "/assets/home-page/cute-laptop-3.jpg",
            type: "other",
            url: "https://github.com/quangle2706/ml-class-quangle/tree/main",
        },
        {
            title: "XYZ University - Student DB",
            description: "I developed a portfolio website showcases ...",
            skill: "Java, JavaFX, MySQL",
            image: "/assets/home-page/cute-laptop-2.jpg",
            type: "other",
            url: "https://github.com/quangle2706/Database-XYZ-University",
        }
        // {
        //     name: "Portfolio",
        //     type: "web", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-web-1.jpg",
        //     skills: "HTML5/CSS3",
        //     url: ""
        // },
        // {
        //     name: "Tank Game",
        //     type: "game", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-game-1.jpg",
        //     skills: "C/C++, Data Structures & Algorithm",
        //     url: ""
        // },
        // {
        //     name: "Data Mining",
        //     type: "other", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-rabbit.jpg",
        //     skills: "HTML5/CSS3",
        //     url: ""
        // },
        // {
        //     name: "Tic Tac Toe",
        //     type: "game", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-game-2.jpg",
        //     skills: "C#, Unity",
        //     url: ""
        // },
        // {
        //     name: "Learning DSA",
        //     type: "mobile", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-mobile-1.jpg",
        //     skills: "Dart/Flutter, Supabase",
        //     url: ""
        // },
        // {
        //     name: "Data Mining",
        //     type: "other", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-rabbit.jpg",
        //     skills: "HTML5/CSS3",
        //     url: ""
        // },
        // {
        //     name: "Portfolio",
        //     type: "web", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-web-1.jpg",
        //     skills: "HTML5/CSS3",
        //     url: ""
        // },
        // {
        //     name: "Tank Game",
        //     type: "game", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-game-1.jpg",
        //     skills: "C/C++, Data Structures & Algorithm",
        //     url: ""
        // },
        // {
        //     name: "Tic Tac Toe",
        //     type: "game", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-game-2.jpg",
        //     skills: "C#, Unity",
        //     url: ""
        // },
        // {
        //     name: "Learning DSA",
        //     type: "mobile", // web, mobile or game
        //     backgroundImg: "/assets/project-page/cute-mobile-1.jpg",
        //     skills: "Dart/Flutter, Supabase",
        //     url: ""
        // },
    ];

    const [leftContentRef, leftContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

      const [rightContentRef, rightContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

    return (
        <>
            <section className="landing-section hero-bg hero-center">
                <div className="container-fluid">
                    <div className="hero-in row mx-3">
                        <div className={`hero-in-left left-content ${leftContentInView ? 'fade-in-left' : ''} col-sm-6`} ref={leftContentRef}>
                            <div className="hero-text">
                                <h1 className="hero-title">The Collection of My Work, More Applications Are Coming ...</h1>
                                <div className="hero-subtitle">
                                    Let's explore how they are, these projects are in several popular platform
                                    in developing applications
                                </div>
                                <div className="hero-btns">
                                    <Link to="https://github.com/quangle2706" target="_blank" className="hero-btn"><span>Explore</span></Link>
                                    {/* <Link to="#" className="hero-btn" target="_blank"><span>Create</span></Link> */}
                                </div>
                            </div>
                        </div>
                        <div className={`hero-in-right right-content ${rightContentInView ? 'fade-in-right' : ''} col-sm-6`} ref={rightContentRef}>
                            <div className="hero-slider">
                                <Slider {...settings}>
                                    {
                                        projectList.map((item, index) => (
                                            <div className="hero-slide" key={index}>
                                                <div className="project-item" >
                                                    <Link to={item.url} className="project-card-thumb project-zoom-effect"><img className="project-zoom-item" src={item.image} alt="" /></Link>
                                                    <div className="project-card-info">
                                                        <h3 className="project-card-title">{item.title}</h3>
                                                        <h6 className="project-card-skill">{item.skill}</h6>
                                                        <hr></hr>
                                                        <div className="project-card-footer"><Link className="card-btn-1" to={item.url}><span>See demo <i className="fa-solid fa-chevron-right"></i></span></Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjectsLanding