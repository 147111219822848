import { Link } from "react-router-dom"

const TagAside = (props) => {

    const tagList = [
        {
            url: "",
            name: "Data Structure"
        },
        {
            url: "",
            name: "Algorithm"
        },
        {
            url: "",
            name: "Lifestyle"
        },
        {
            url: "",
            name: "Music"
        },
    ]

    return (
        <>
            <div className={`tag-aside ${props.className}`}>
                <div className="tag-aside-title title medium">Tag</div>
                <div className="tag-list">
                    {
                        tagList.map((tag, index) => (
                            <Link className="tag-link" to={tag.url} key={index}>{tag.name}</Link>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default TagAside