import { Link } from "react-router-dom";

const AboutMeAside = () => {
    return (
        <>
            <div className="about-me-aside bg-white">
                <div className="about-title title medium">About Me</div>
                <div className="about-author">
                    <div className="author-avatar avatar-effect">
                        <img src="assets/blog-page/cute-avatar.jpg" alt="" />
                    </div>
                    <div className="author-info">
                        <h4>Quang Tuan Le</h4>
                        <h6>Founder & Developer</h6>
                        <div className="social-icons">
                            {/* <Link to="#"><i className="fa-brands fa-facebook-f"></i></Link>
                            <Link to="#"><i className="fa-brands fa-instagram"></i></Link> */}
                            <Link to="https://github.com/quangle2706" target="_blank"><i className="fa-brands fa-github"></i></Link>
                            <Link to="https://www.linkedin.com/in/quang-tuan-le-498474214/" target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
                        </div>
                    </div>
                </div>
                <p>Hello! My name is Quang Tuan Le. I am a software engineer, and I love to create applications.</p>
            </div>
        </>
    );
}

export default AboutMeAside