import { Link } from "react-router-dom";

const CommentSection = () => {
    return (
        <>
            <section className="comment-section">
                <div className="comment-title">Comments</div>
                <div className="comment-list">
                    <div className="comment-view">
                        <div className="commenter-avatar"><img width="50px" src="assets/blog-page/cute-dog.jpg" alt="" /></div>
                        <div className="comment-content">
                            <div className="commenter-name">Anonymous <span>on August 10, 2023</span></div>
                            <div className="comment-description">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In feugiat dui a velit commodo, in sagittis odio imperdiet. Praesent rutrum, metus et luctus viverra, nisl risus pharetra nisi, at aliquet felis justo et velit.</p>
                            </div>
                            <div className="inline-flex"><Link className="button-style-2">Reply</Link></div>
                            {/* <!-- Here another comment --> */}
                            <div className="horizontal-line"></div>
                            <div className="comment-view">
                                <div className="commenter-avatar"><img width="50px" src="assets/blog-page/cute-dog.jpg" alt="" /></div>
                                <div className="comment-content">
                                    <div className="commenter-name">Anonymous <span>on August 10, 2023</span></div>
                                    <div className="comment-description">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In feugiat dui a velit commodo, in sagittis odio imperdiet. Praesent rutrum, metus et luctus viverra, nisl risus pharetra nisi, at aliquet felis justo et velit.</p>
                                    </div>
                                    <div className="inline-flex"><Link className="button-style-2">Reply</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Here another comment --> */}
                    <div className="horizontal-line"></div>
                    <div className="comment-view">
                        <div className="commenter-avatar"><img width="50px" src="assets/blog-page/cute-dog.jpg" alt="" /></div>
                        <div className="comment-content">
                            <div className="commenter-name">Anonymous <span>on August 10, 2023</span></div>
                            <div className="comment-description">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In feugiat dui a velit commodo, in sagittis odio imperdiet. Praesent rutrum, metus et luctus viverra, nisl risus pharetra nisi, at aliquet felis justo et velit.</p>
                            </div>
                            <div className="inline-flex"><Link className="button-style-2">Reply</Link></div>
                        </div>
                    </div>
                </div>
                <h4>Leave a Reply</h4>
                <form id="commentForm">
                    <textarea id="comment" name="comment" placeholder="Comment"></textarea>
                    <input className="form-author" type="text" id="name" name="name" placeholder="Name" required />
                    <input className="form-email" type="email" id="email" name="email" placeholder="Email" required />
                    <div className="clear"></div>
                    <input className="form-website" type="text" id="website" name="website" placeholder="Website" />
                    <p className="comment-form-cookies-consent">
                        <input id="comment-cookies-consent" name="comment-cookies-consent" type="checkbox" value="yes" />
                        <label htmlFor="comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                    </p>
                    <button className="button-style-2" type="submit">Post Comment</button>
                </form>
            </section>
        </>
    );
}

export default CommentSection