import RecentPostsAside from "../pages/Blogs/components/RecentPostsAside";
import FollowMeAside from "../pages/Blogs/components/FollowMeAside";
import TagAside from "../pages/Blogs/components/TagAside";

const BlogFooter = () => {

    return (
        <>
            <footer id="blogFooter" className="">
                <div className="row mx-4">
                    <div className="footer-author col-lg-3 col-md-6 col-sm-12 py-4">
                        <h2>Quang Le</h2>
                        <p>A super modern theme following the latest trends with premium Membership and fully compatible with Ghost.<br></br><br></br>
                            Check more themes like this on thequangle.com</p>
                    </div>
                    <RecentPostsAside className="col-lg-3 col-md-6 col-sm-12 " />
                    <FollowMeAside className="col-lg-3 col-md-6 col-sm-12" />
                    <TagAside className="col-lg-3 col-md-6 col-sm-12" />
                </div>
            </footer>
        </>
    );
}

export default BlogFooter