import AboutHeader from "../../components/AboutHeader";
import AboutContact from "./components/AboutContact";
import AboutEducation from "./components/AboutEducation";
import AboutExperience from "./components/AboutExperience";
import AboutIntro from "./components/AboutIntro";
import AboutMe from "./components/AboutMe";
import AboutPortfolio from "./components/AboutPortfolio";
import AboutSkills from "./components/AboutSkills";

export default function AboutPage() {
    return (
        <div id="pageWrapper">
            <AboutHeader />
            <main id="pageContentWrapper">
                <AboutIntro />
                <AboutMe />
                <AboutSkills />
                <AboutExperience />
                <AboutEducation />
                <AboutPortfolio />
                <AboutContact />
            </main>
            <footer></footer>
        </div>
    );
}