import { ChakraProvider } from "@chakra-ui/react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import HomeBadge from "./components/HomeBadge";
import HomeBlog from "./components/HomeBlog";
import HomeCareer from "./components/HomeCareer";
import HomeContact from "./components/HomeContact";
import HomeHero from "./components/HomeHero";
import HomeOverview from "./components/HomeOverview";
import HomeProject from "./components/HomeProject";
import { AlertProvider } from "../context/AlertContext";
import Alert from "../../components/Alert";

function HomePage() {
    return (
        <>
            <ChakraProvider>
                <AlertProvider>
                    <Header />
                    <main>
                        <HomeHero />
                        <HomeOverview />
                        <HomeCareer />
                        <HomeBadge />
                        <HomeProject />
                        <HomeBlog />
                        <HomeContact />
                        <Alert />
                    </main>
                    <Footer />
                </AlertProvider>
            </ChakraProvider>
        </>
    );
}

export default HomePage