const AboutMe = () => {
    return (
        <>
            <section id="sectionAbout" className="section-about">
                <div className="container">
                    <div className="section-heading">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>About me</h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="block-about-me">
                            <div className="row">
                                <div className="col-md-5 col-sm-6">
                                    <div className="about-avatar-wrapper">
                                        <img className="mw-100 about-avatar" src="/assets/Logo-cute-3.png" alt=""/>
                                        <button className="about-btn btn-cta btn-cta-solid"><a href="/assets/quangle_resume_all_rebuild.pdf" style={{color: "#fff"}} download>Download resume as .pdf</a></button>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-6">
                                    <ul className="about-me-list">
                                        <li><span>Name: </span>QUANG LE</li>
                                        <li><span>Email: </span>quangle2706@gmail.com</li>
                                        <li><span>What do I know: </span><br></br>Energetic and innovative new graduate software engineer with a passion for crafting elegant solutions and pushing the boundaries of technology. Equipped with a solid foundation in computer science principles and a hands-on approach to problem-solving. Dedicated to honing skills in software development, collaborating effectively within teams, and delivering high-quality code. Excited to contribute fresh ideas and a strong work ethic to dynamic projects that foster growth and drive positive impact.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="block-strengths">
                            <div className="row">
                                <div className="col-md-2 col-sm-4 col-xs-6">
                                    <img className="mw-100 strength-icon" src="/assets/strengths/design-thinking.png" alt=""/>
                                    <span className="strength-heading">Creative</span>
                                </div>
                                <div className="col-md-2 col-sm-4 col-xs-6">
                                    <img className="mw-100 strength-icon" src="/assets/strengths/time-management.png" alt=""/>
                                    <span className="strength-heading">Dedicated</span>
                                </div>
                                <div className="col-md-2 col-sm-4 col-xs-6">
                                    <img className="mw-100 strength-icon" src="/assets/strengths/collaboration.png" alt=""/>
                                    <span className="strength-heading">Collaborative</span>
                                </div>
                                <div className="col-md-2 col-sm-4 col-xs-6">
                                    <img className="mw-100 strength-icon" src="/assets/strengths/passion.png" alt=""/>
                                    <span className="strength-heading">Passionate Coder</span>
                                </div>
                                <div className="col-md-2 col-sm-4 col-xs-6">
                                    <img className="mw-100 strength-icon" src="/assets/strengths/continuous-improvement.png" alt=""/>
                                    <span className="strength-heading">Continuous Learner</span>
                                </div>
                                <div className="col-md-2 col-sm-4 col-xs-6">
                                    <img className="mw-100 strength-icon" src="/assets/strengths/meticulous.png" alt=""/>
                                    <span className="strength-heading">Meticulous Tester</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutMe