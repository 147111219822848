import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';
import { Link } from "react-router-dom";

const WorkIcon = (props) => {
    return (
        <>
            <div className="">
                <img src={props.urlImage} alt=""/>
            </div>
        </>
    );
}

const HomeCareer = () => {
    return (
        <>
            <section className="career-section">
                <div className="container py-5">
                    <div className="row">
                        <div className="home-topic text-white">What I have done so far</div>
                        <h1 className="home-title text-white">My Path</h1>
                        <VerticalTimeline>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                date="2023"
                                iconStyle={{ background: 'white', color: '#fff' }}
                                icon={<WorkIcon urlImage="/assets/flaticon/meta.png" />}
                            >
                                <h3 className="vertical-timeline-element-title home-topic"><Link target="_blank" to="https://coursera.org/share/fdaa804787aa8c4244c194c5864a8a9f">Meta Front-End Developer Certificate</Link></h3>
                                <h4 className="vertical-timeline-element-subtitle home-subtitle small">Coursera, Meta</h4>
                                <p>
                                Course Certificates Completed
                                </p>
                                <ol>
                                    <li>Principles of UX/UI Design</li>
                                    <li>HTML and CSS in depth</li>
                                    <li>Introduction to Front-End Development</li>
                                    <li>React Basics</li>
                                    <li>Advanced React</li>
                                    <li>Programming with JavaScript</li>
                                    <li>Front-End Developer Capstone</li>
                                </ol>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                date="2022 - 2023"
                                iconStyle={{ background: 'white', color: '#fff' }}
                                icon={<WorkIcon urlImage="/assets/flaticon/fiu-brand.png" />}
                            >
                                <h3 className="vertical-timeline-element-title home-topic">Florida International University</h3>
                                <h4 className="vertical-timeline-element-subtitle home-subtitle small">Miami, FL</h4>
                                <p>
                                Bachelor of Arts in Computer Science, GPA: 4.0 Summa Cum Laude
                                </p>
                                <ol>
                                    <li>Courses: Parallel Programming, Data Structures, Systems Programming, Programming I/II, Data Mining, Database Management, Logic in Computer Science, Principles of Programming Languages</li>
                                    <li>Dean's List Spring 2022, Fall 2022, Spring 2023</li>
                                </ol>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                date="2013 - 2017"
                                iconStyle={{ background: 'white', color: '#fff' }}
                                icon={<WorkIcon urlImage="/assets/flaticon/logo-hcmus-new.webp" />}
                            >
                                <h3 className="vertical-timeline-element-title home-topic">University of Science</h3>
                                <h4 className="vertical-timeline-element-subtitle home-subtitle small">Ho Chi Minh City, Vietnam</h4>
                                <p>
                                Bachelor of Science in Software Engineering, GPA: 8.02/10
                                </p>
                                <ol>
                                    <li>Courses: Object-Oriented Programming, Windows Programming, Java Programming, Advanced Mobile Development, Game Development, Web Development</li>
                                    <li>Experienced in C/C++, Java, Python, Nodejs, SQL/MySQL, AngularJS, HTML5/CSS3, JavaScript</li>
                                </ol>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                iconStyle={{ background: 'white', color: '#fff' }}
                                icon={<WorkIcon urlImage="/assets/flaticon/cute-pig.png" />}
                            />
                            </VerticalTimeline>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeCareer