import { Link } from "react-router-dom";

const ProjectFooter = () => {
    return (
        <>
            <footer className="project-footer">
                <div className="container">
                    <div className="row text-center">
                        <div className="project-footer-widget">
                            <h3 className="widget-title">Copyright © 2023 • Quang Tuan Le - The One and Only</h3>
                            <div className="footer-social-btns">
                                {/* <Link to="#"><i className="fab fa-facebook-f fa-fw"></i></Link> */}
                                <Link to="https://github.com/quangle2706/" target="_blank"><i className="fab fa-github fa-fw"></i></Link>
                                <Link to="https://www.linkedin.com/in/quang-tuan-le-498474214/" target="_blank"><i className="fab fa-linkedin-in fa-fw"></i></Link>
                                {/* <Link to="#"><i className="fab fa-instagram fa-fw"></i></Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default ProjectFooter;