import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const HomeOverview = () => {

    const contentList = [
        {
            img: "/assets/flaticon/web-development.png",
            title: "Frontend Developer",
            bgColor: "#FA8072"
        },
        {
            img: "/assets/flaticon/coding.png",
            title: "Backend Developer",
            bgColor: "#48C9B0"
        },
        {
            img: "/assets/flaticon/app-development.png",
            title: "Mobile App Developer",
            bgColor: "#F4D03F"
        },
        {
            img: "/assets/flaticon/legacy-system.png",
            title: "Desktop App Developer",
            bgColor: ""
        },
    ]

    const [downContentRef, downContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

    return (
        <>
            <section className={`overview-section down-content ${downContentInView ? 'fade-in-down' : ''}`} ref={downContentRef}>
                <div className="container py-5">
                    <div className="overview-title mb-4">
                        <div className="home-topic dark-text-color">Introduction</div>
                        <h1 className="home-title dark-text-color">Overview</h1>
                        <h6 className="home-subtitle dark-text-color">
                        I have always been captivated by the possibilities of technology, and my journey as a software engineer allows me to explore and create in the realms of web and mobile app development as well as machine learning. This website servers as a platform for me to share my knowledge, experiences, and projects in these exciting fields.
                        </h6>
                    </div>
                    <div className="overview-content">
                        <div className="container">
                            <div className="content-box-list">
                            {
                                contentList.map((content, index) => (
                                    <Link className="overview-content-box" key={index} style={{backgroundColor: content.bgColor}}>
                                        <div className="content-img"><img className="" style={{padding: "20px"}} src={content.img} alt="" /></div>
                                        <span className="content-title">{content.title}</span>
                                    </Link>
                                ))
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeOverview