import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const HomeProject = () => {

    const projectList = [
        {
            title: "Portfolio Website",
            description: "I developed a portfolio website showcases ...",
            skill: "ReactJS, HTML5/CSS3",
            image: "/assets/home-page/cute-laptop-1.jpg",
            url: "https://thequangle.com",
        },
        {
            title: "DSA Learning",
            description: "",
            skill: "Mobile Development, Flutter",
            image: "/assets/home-page/cute-laptop-4.jpg",
            url: "https://github.com/quangle2706/my_dsa_app",
        },
        {
            title: "Data Mining",
            description: "",
            skill: "Python, Colab, Numpy/Pandas, Keras",
            image: "/assets/home-page/cute-laptop-3.jpg",
            url: "https://github.com/quangle2706/ml-class-quangle/tree/main",
        },
        {
            title: "XYZ University - Student DB",
            description: "I developed a portfolio website showcases ...",
            skill: "Java, JavaFX, MySQL",
            image: "/assets/home-page/cute-laptop-2.jpg",
            url: "https://github.com/quangle2706/Database-XYZ-University",
        }
    ]

    const [downContentRef, downContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

    return (
        <>
            <section className={`home-project`}>
                <div className={`container py-5 down-content ${downContentInView ? 'fade-in-down' : ''}`} ref={downContentRef}>
                    <div className="row">
                        <div className="project-overview mb-4">
                            <div className="home-topic">My Projects</div>
                            <h1 className="home-title">Projects</h1>
                            <h6 className="home-subtitle">Following projects showcases my inspiration in application development</h6>
                        </div>
                        <div className={`project-content`}>
                            <div className="container">
                                <div className="project-list">
                                    {
                                        projectList.map((project, index) => (
                                            <div className="home-project-card" key={index}>
                                                <div className="home-project-img">
                                                    <img src={project.image} alt="" />
                                                </div>
                                                <div className="home-project-card-content p-3">
                                                    <h3 className="home-title mb-2 small">{project.title}</h3>
                                                    {/* <div className="home-subtitle mb-1 small">{project.description}</div> */}
                                                    <div className="home-subtitle mb-3 small">{project.skill}</div>
                                                    <span><Link className="button-87 rounded-0" to={project.url} target="_blank">See the demo</Link></span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeProject