import ProjectsLanding from './components/ProjectsLanding';
import ProjectsProject from './components/ProjectsProject';
import ProjectFooter from '../../components/ProjectFooter';
import ProjectHeader from '../../components/ProjectHeader';

export default function ProjectsPage() {

    return (
        <div className='project-body-dark-theme'>
            <ProjectHeader />
            <main>
                <ProjectsLanding />
                <ProjectsProject />
            </main>
            <ProjectFooter />
        </div>
    );
}