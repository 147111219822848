import { Link } from "react-router-dom";

const AboutIntro = () => {
    return (
        <>
            <section id="sectionIntro" className="section-intro" style={{backgroundImage: "url(assets/Logo-cute-3.png)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-content">
                                <h1>Hello, I'm <span>Quang Le</span></h1>
                                <h2>Software Engineer / Frontend Web Developer</h2>
                                <Link to="https://www.linkedin.com/in/quang-tuan-le-498474214/" target="_blank" className="about-btn btn-cta">Hire Me</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutIntro