const AboutPortfolio = () => {
    return (
        <>
            <section id="sectionPortfolio" className="section-portfolio">
                <div className="container">
                    <div className="section-heading">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Portfolio</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    
                </div>
            </section>
        </>
    );
}

export default AboutPortfolio