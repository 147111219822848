const AboutExperience = () => {
    return (
        <>
            <section id="sectionExperience" className="section-experience">
                <div className="container">
                    <div className="section-heading">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Experience</h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="block-my-experience">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>3 years experience</h3>
                                    <p></p>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="block-timeline">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="timeline">
                                        <li className="event">
                                            <input id="checkbox-exp1" type="radio" name="exp-tl-group" checked />
                                            <label></label>
                                            <div className="thumb company1">
                                                <span>2016</span>
                                            </div>
                                            <div className="content-perspective">
                                                <div className="content">
                                                    <div className="content-inner">
                                                        <h3>Internship Frontend web developer in Solazu JSC</h3>
                                                        <p>
                                                            Completed two websites and published to Themeforest.net, ALAB and Music, meeting all project deadlines.
                                                            <br></br>Accomplished responsive and user-friendly interfaces using HTML, CSS, and JavaScript.
                                                            <br></br>Collaborated closely with backend team to perform necessary changes and updates to building WordPress.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {/* <li className="event">
                                            <input id="checkbox-exp1" type="radio" name="exp-tl-group" />
                                            <label></label>
                                            <div className="thumb company1">
                                                <span>2022 - Now</span>
                                            </div>
                                            <div className="content-perspective">
                                                <div className="content">
                                                    <div className="content-inner">
                                                        <h3>Frontend web developer in freelance</h3>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}
                                        {/* <li className="event">
                                            <input id="checkbox-exp1" type="radio" name="exp-tl-group" />
                                            <label></label>
                                            <div className="thumb company1">
                                                <span>2022 - Now</span>
                                            </div>
                                            <div className="content-perspective">
                                                <div className="content">
                                                    <div className="content-inner">
                                                        <h3>Frontend web developer in freelance</h3>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutExperience