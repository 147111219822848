import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const HomeBadge = () => {

    const badges = [
        {
            background: "",
            image: "/assets/home-page/html5-logo.png",
            url: "https://coursera.org/share/2e1de8ac27b798ebb18f87e25c50d054",
        },
        {
            background: "",
            image: "/assets/home-page/css3-logo.png",
            url: "https://coursera.org/share/48b73d6a94ef8411653196a92e5b7d18",
        },
        {
            background: "",
            image: "/assets/home-page/javascript-logo.png",
            url: "https://coursera.org/share/d68c233246efedfad8d879663a408214",
        },
        {
            background: "",
            image: "/assets/home-page/reactjs-logo.png",
            url: "https://coursera.org/share/a9d60fbd46b876fecdc74cc2a8749eb7",
        },
        {
            background: "",
            image: "/assets/home-page/java-logo.png",
            url: "",
        },
        {
            background: "",
            image: "/assets/home-page/bootstrap-logo.png",
            url: "https://coursera.org/share/48b73d6a94ef8411653196a92e5b7d18",
        },
        {
            background: "",
            image: "/assets/home-page/git.png",
            url: "https://www.coursera.org/account/accomplishments/verify/98L5NEK7JBMZ",
        },
    ]

    const [leftContentRef, leftContentInView] = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as per your requirement
    });

    return (
        <>
            <section className="badge-section">
                <div className="container py-5">
                    <div className={`badge-list left-content ${leftContentInView ? 'fade-in-left' : ''}`} ref={leftContentRef}>
                    {
                        badges.map((badge, index) => (
                            <Link to={badge.url} className="octagon" key={index} style={{}} target="_blank">
                                <div className="badge-img">
                                    <img src={badge.image} alt="" />
                                </div>
                            </Link>
                        ))
                    }
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeBadge